@import 'styles/variable.less';

.mainNotStarted,
.mainInProgress,
.mainWaitInProgress,
.mainCompleted,
.mainClosed {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  .statusText {
    display: flex;
    justify-content: space-between;
  }
  .statusError {
    color: @red !important;
  }
}
.mainNotStarted {
  .statusText {
    > span {
      color: @red !important;
    }
  }
}
.mainInProgress {
  .statusText {
    > span {
      color: @orange !important;
    }
  }
}
.mainCompleted {
  .statusText {
    > span {
      color: @green !important;
    }
  }
}
.mainClosed {
  .statusText {
    > span {
      color: @primary !important;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;