@import 'styles/variable.less';

.dem-monochrome-linear,
.dem-jet-linear {
  height: 13px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.dem-monochrome-linear {
  background: linear-gradient(90deg, #f5f5f5, #202020);
}
.dem-jet-linear {
  background: linear-gradient(90deg, #d7191c, #fdae61, #ffffbf, #abdda4, #2b83ba);
}

.reading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  > span {
    font-weight: 500;
    font-size: 10px;
    color: @black;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;