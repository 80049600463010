@import 'styles/variable.less';

.remove-member-content {
  .remove-member-title {
    width: 100%;
    padding: 9px 5px;
    border-bottom: 0.5px solid @lightWhite2;
    > div {
      > span {
        font-weight: 600;
        font-size: 12px;
        color: @btnTitle;
        text-shadow: none;
        letter-spacing: 0.5px;
      }
    }
  }
  .checkbox-content-col {
    padding: 9px 5px;
    border-bottom: 0.5px solid @lightWhite2;
    .checkbox-content {
      align-items: center;
      span:first-child {
        top: 0;
        margin-right: 5px;
      }
    }
    .checkbox-innercontent {
      .name-avatar {
        background-color: @white;
        border: 0.5px solid @avatarTitle;
        color: @avatarTitle;
      }
      span:nth-child(2) {
        padding-left: 8px;
        color: @avatarTitle;
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;