@import 'styles/variable.less';

.main-analytics {
  width: 100%;
  height: calc(100vh - 127px);
  background-color: @white;
  padding: 15px;
  .main-tab-content {
    height: 100%;
    > div:first-child {
      margin-bottom: 13px;
      &:before {
        display: none;
      }
    }
    > div {
      > div {
        > div {
          background: @analyticBack;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
          border-radius: 2px;
          > div:first-child,
          > div:nth-child(2) {
            > div {
              .tab-title-inr {
                font-weight: 600;
                font-size: 12px;
                color: @analyticTitle;
                text-shadow: none;
                letter-spacing: 0.5px;
                text-transform: uppercase;
              }
            }
            padding: 5px 9px 5px 7px;
          }
          .ant-tabs-tab-active {
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            border-radius: 2px;
            > div {
              .tab-title-inr {
                color: @primary !important;
                text-shadow: none;
                letter-spacing: 0.5px;
              }
            }
          }
          > div {
            &:nth-child(2) {
              margin-left: 5px;
              padding-left: 21px;
              padding-right: 21px;
            }
          }
        }
      }
    }
    .feature-tab,
    .cost-tab {
      height: calc(100vh - 205px);
      border: 1px solid @primary;
      border-radius: 2px;
      background-color: @white;
    }
    .feature-tab {
      .feature-table {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
              > div {
                height: 100%;
                > div {
                  &::-webkit-scrollbar {
                    width: 5px;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: @veryLightGray;
                    border-radius: 3px;
                  }
                  > table {
                    > thead {
                      background: @whiteShade;
                      > tr {
                        > th {
                          border-bottom: 1px solid @dividerBack;
                          font-weight: 500;
                          font-size: 14px;
                          color: @roottableth;
                          &:before {
                            display: none;
                          }
                        }
                      }
                    }
                    > tbody {
                      tr {
                        &:hover td {
                          background: unset;
                        }
                        &:nth-child(2) {
                          background-color: @rootRowBack !important;
                          > td {
                            color: @blackShade !important;
                          }
                        }
                        &:nth-child(odd) {
                          background-color: @whiteShade;
                        }
                        &:nth-child(even) {
                          background-color: @white;
                        }
                        > td {
                          color: @roottableth;
                          font-weight: 500;
                          font-size: 14px;
                          padding: 7px 16px;
                        }
                      }
                      .empty-row {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .no-feature-data {
        > div {
          > div {
            > div {
              > div {
                > div {
                  > table {
                    > tbody {
                      tr {
                        &:nth-child(2) {
                          background-color: @white !important;
                        }
                        > td {
                          border: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.upload-csv {
  position: absolute;
  right: 17px;
  top: 134px;
  padding: 4px 34px !important;
  border: 1px solid @primary;
  cursor: pointer;
  border-radius: 4px;
  > div {
    > span {
      font-weight: 500;
      color: @primary;
    }
  }
  &:disabled {
    > span {
      color: @avatarTitle;
    }
    &:hover {
      > span {
        color: @avatarTitle;
      }
    }
  }

  &:hover {
    background-color: @primary;
    transition: 0.3s;
    > span {
      color: @white;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;