@import 'styles/variable.less';

.cost-tab-inr {
  height: 100%;
  background-color: @white;
  .main-tabs-inr {
    height: 100%;
    margin: 5px 5px 5px 0 !important;
    > div:first-child {
      > div {
        > div {
          > div {
            padding: 0px 13px;
          }
        }
      }
    }
    > div:last-child {
      border-left: 1px solid @primary;
      margin-bottom: 10px;
      margin-right: 0px;
      > div {
        height: 100%;
        .unit-cost-tab {
          height: 100%;
        }
      }
    }

    > div {
      &:last-child {
        .soft-cost-form {
          height: 94%;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0px;
          }
          .form-submit-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > button {
              padding: 4px 50px;
              border-radius: 2px;
              @media (max-width: 1440px) {
                padding: 4px 50px;
              }
            }
          }
        }

        > div {
          .ant-tabs-tabpane {
            padding-left: 19px;
            padding-right: 0px;
          }
          .unit-cost-title-inr {
            color: @primary;
            font-weight: 600;
            font-size: 16px;
            text-shadow: none;
            text-decoration: none;
            letter-spacing: 0.3px;
          }
          .unit-soft-cost-row {
            padding: 6.5px 0 6.5px 11px;
            background: @lightWhite;
            border-radius: 2px;
            margin-top: 10px;
            margin-bottom: 12px;
            .soft-cost-title {
              font-weight: 600;
              font-size: 16px;
              color: @roottableth;
            }
          }
          .soft-cost-feature {
            padding-left: 10px;
            > div:nth-child(2) {
              margin: 0;
              margin-top: 10px;
              background-color: @dividerBack;
            }
            .soft-cost-feature-title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              > span {
                font-weight: 500;
                font-size: 14px;
                color: @roottableth;
                letter-spacing: 0.3px;
              }
            }
          }
          .soft-cost-form-div {
            margin-top: 10px;
            .soft-cost-item {
              margin-bottom: 8px;

              > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                > div:first-child {
                  width: 90%;
                  text-align: start;
                  @media (max-width: 1440px) {
                    width: 85%;
                  }
                  > label {
                    &:after {
                      display: none;
                    }
                  }
                }
                > div:last-child {
                  text-align: end;
                  > div {
                    > div {
                      > div {
                        width: 100%;
                        box-shadow: none;
                        border: 0px;
                        > div:first-child {
                          display: none;
                        }
                        > div:last-child {
                          > input {
                            background-color: @lightWhite1;
                            border: 0;
                            text-align: end;
                            color: #868686;
                            &:hover {
                              box-shadow: none;
                              border: 0;
                              outline: 0;
                            }
                            &:active {
                              box-shadow: none;
                              outline: 0;
                              border: 0;
                            }
                            &:focus {
                              box-shadow: none;
                              outline: 0;
                              border: 0;
                            }
                            &:focus:hover,
                            &:active:hover {
                              border: 0;
                              outline: 0;
                              box-shadow: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .tower-type {
              padding-left: 20px;
            }
          }
        }
      }
      > div {
        > div {
          > div {
            > div {
              > button {
                height: 30px;
                width: 130px;
                background: @white;
                border-radius: 2px;
                > span {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .unit-cost-btn {
                > span {
                  color: @primary;
                }
              }
              // .route1-btn {
              //   > span {
              //     color: @pink;
              //   }
              // }
              // .route2-btn {
              //   > span {
              //     color: @lightgreen;
              //   }
              // }
              // .route3-btn {
              //   > span {
              //     color: @lightBlue;
              //   }
              // }
            }
          }
          .ant-tabs-tab-active {
            box-shadow: none !important;
            .unit-cost-btn {
              border: 1px solid @primary;
              box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15) !important;
              > span {
                color: @primary;
              }
            }
            // .route1-btn {
            //   border: 1px solid @pink;
            //   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15) !important;
            //   > span {
            //     color: @pink;
            //   }
            // }
            // .route2-btn {
            //   border: 1px solid @lightgreen;
            //   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15) !important;
            //   > span {
            //     color: @lightgreen;
            //   }
            // }
            // .route3-btn {
            //   border: 1px solid @lightBlue;
            //   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15) !important;
            //   > span {
            //     color: @lightBlue;
            //   }
            // }
          }
          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
      .route1-tab,
      .route2-tab,
      .route3-tab {
        height: 100%;
        > div {
          height: 100%;
        }
        .route1-tab-title {
          height: 40px;
          background: @lightPink;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 11px 0 23px;
          .route1-tab-title-inr,
          .route1-tab-cost,
          .route1-tab-length,
          .route1-tab-cost-km {
            font-weight: 600;
            font-size: 14px;
            color: @primary;
            text-shadow: none;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            > span {
              text-shadow: none;
              letter-spacing: 0.3px;
              color: @primary;
            }
          }
        }
        .scroll-content {
          height: 92.5%;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0px;
          }
          @media (max-width: 1330px) {
            height: 90%;
          }
        }
        .route1-soft-cost-row,
        .route1-technical-cost-row {
          height: 40px;
          background: @lightWhite;
          border-radius: 2px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          font-weight: 600;
          letter-spacing: 0.3px;
          text-shadow: none;
          > span:first-child {
            font-size: 16px;
            color: @roottableth;
          }
          > span:last-child {
            font-size: 14px;
            color: @primary;
            > span {
              color: @primary;
            }
          }
        }
        .route1-technical-cost-row {
          margin-top: 20px;
        }
        .route1-soft-cost,
        .route1-technical-cost,
        .route1-cable-cost {
          margin-top: 12px;
          padding-left: 12px;
          > table {
            width: 100%;
            padding-right: 20px;
            > thead {
              border-bottom: 1px solid @dividerBack;
              > tr {
                > th {
                  text-align: left;
                  padding-bottom: 3px;
                  font-weight: 500;
                  font-size: 14px;
                  color: @roottableth;
                  &:first-child {
                    width: 30%;
                  }
                  &:nth-child(2) {
                    width: 20%;
                  }
                  &:nth-child(3) {
                    width: 20%;
                  }
                  &:last-child {
                    text-align: end;
                    width: 10%;
                  }
                }
              }
            }
            > tbody {
              &:before {
                content: '-';
                display: block;
                line-height: 7px;
                color: transparent;
              }
              > tr {
                > td {
                  padding-bottom: 5px;
                  > span {
                    &:last-child {
                      > input {
                        padding: 4px 2px;
                        background-color: @lightPink;
                        outline: 0;
                        border: 0;
                        border-radius: 0;
                        box-shadow: none !important;
                        text-align: end;
                        &:focus,
                        &:hover,
                        &:focus:hover {
                          outline: 0;
                          border: 0;
                          border-radius: 0;
                          box-shadow: none !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .total-feature-cost,
        .total-tower-cost,
        .total-cable-cost {
          background-color: @lightPink;
          height: 32px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 10px;
          margin-top: 20px;
          > span {
            font-weight: 500;
            font-size: 12px;
            color: @primary;
            text-shadow: none;
            letter-spacing: 0.3px;
            > span {
              letter-spacing: 0.3px;
              color: @primary;
            }
          }
        }
      }
      .route2-tab {
        .route1-soft-cost,
        .route1-technical-cost,
        .route1-cable-cost {
          > table {
            > tbody {
              > tr {
                > td {
                  > span {
                    &:last-child {
                      > input {
                        background-color: @veryLightgreen;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .route1-tab-title {
          background-color: @veryLightgreen;
        }
        .total-feature-cost,
        .total-tower-cost,
        .total-cable-cost {
          background-color: @veryLightgreen;
        }
      }
      .route3-tab {
        .route1-soft-cost,
        .route1-technical-cost,
        .route1-cable-cost {
          > table {
            > tbody {
              > tr {
                > td {
                  > span {
                    &:last-child {
                      > input {
                        background-color: @verylightblue;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .route1-tab-title {
          background-color: @verylightblue;
        }
        .total-feature-cost,
        .total-tower-cost,
        .total-cable-cost {
          background-color: @verylightblue;
        }
      }
    }
  }
}
.tower-type-title {
  padding-left: 20px;
}
.inr-divider {
  margin: 6px 0;
  height: 1px;
  background-color: @dividerBack;
}
.total-route1-cost {
  margin-top: 20px;
  background-color: @primary;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  > span {
    font-weight: 600;
    font-size: 14px;
    color: @white;
    text-shadow: none;
    letter-spacing: 0.3px;
    > span {
      color: @white;
      letter-spacing: 0.3px;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;