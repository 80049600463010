@import 'styles/variable.less';
@import 'styles/image.less';

.mainRow {
  background-image: url(@login-background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .logoContent {
    position: absolute;
    top: 50px;
    left: 116px;
    width: 150px;
    height: 150px;
    object-fit: 'contain';
  }
  .formContent {
    margin-right: 100px;
    .mainLoginCard {
      padding: 70px 35px 50px 35px !important;
      border-radius: 10px;
      width: 350px;
      .forgotPasswordBtn {
        display: flex;
        justify-content: center;
      }
      .mainLoginForm {
        .formTitle {
          font-weight: 600;
          color: @primary;
          width: 320px;
        }
        > .inputField {
          margin-top: 30px;
          > div {
            > div {
              > label {
                color: @primary !important;
              }
            }
          }
          .inputLabel:focus {
            background-color: transparent !important;
            border: 0 !important;
            border-bottom: 1px solid @borderBottom !important;
            box-shadow: none !important;
            color: @primary;
            font-weight: 500;
            > input:focus {
              background-color: transparent !important;
              border: 0 !important;
              box-shadow: none !important;
              color: @primary;
              font-weight: 500;
            }
          }
          .inputLabel {
            border: 0;
            border-bottom: 1px solid @borderBottom !important;
            color: @primary;
            > input {
              color: @primary;
            }
          }
        }
      }
      > div {
        padding: 0 !important;
        > p {
          padding-top: 10px !important;
          text-align: center;
          font-weight: 600;
          color: @primary;
        }
        .formBottomLogo {
          text-align: center;
          margin-top: 50px;
          > p {
            color: @primary;
            margin: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;