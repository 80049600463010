@import 'styles/variable.less';

.repositoryForm {
  border: 1px solid @lightGray;
  border-radius: 3px;
  padding: 15px 20px;
  padding-bottom: 0;
  .uplodeFile {
    > div {
      > div {
        label {
          > span {
            font-size: 18px;
            color: @primary;
            font-weight: 500;
            > span {
              color: @red;
            }
          }
        }
      }
    }
    .uplodeFileBtn {
      width: 150px;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .file-input {
      border: 1px solid @avatarTitle;
      &:hover {
        border: 1px solid @avatarTitle;
      }
      > input {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: @primary;
      }
    }
    .uplodeFileBtn,
    .selectCategory,
    .innerBufferContent {
      border: 1px solid @avatarTitle;
      &:hover {
        border: 1px solid @avatarTitle;
      }
      > input {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: @primary;
      }
    }
    .innerBufferContent {
      display: flex;
      .bufferM {
        display: flex;
        align-items: center;
        background-color: @veryLightGray;
        color: @greyShade1;
        font-weight: 500;
        padding: 7px 12px;
      }
    }
    .addCommentsInput {
      background-color: @secondary;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: @primary;
      padding: 9px 11px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .uplodeBtnCol {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    > button {
      padding: 7px 40px;
    }
  }
}
.ant-select-selection-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: @primary;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;