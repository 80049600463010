@import 'styles/variable.less';

.main-title-content {
  .project-name-list {
    width: 192px;
    .ant-select-selector {
      border: 1px solid @primary;
      box-shadow: none !important;
      .ant-select-selection-placeholder {
        color: @primary;
      }
      .ant-select-selection-item {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .ant-select-arrow {
      > span {
        > svg {
          fill: @primary;
        }
      }
    }
  }
}
.main-title-map-view {
  padding: 10px 24px;
}
.ant-select-item-option-selected {
  background-color: @lightdarkGray !important;
  > div {
    color: @primary;
    font-weight: 500;
    background-color: @lightdarkGray;
  }
}
.main-map-view {
  height: calc(100vh - 140px);
  overflow-y: auto;
  margin: 0 20px 0px 20px !important;
  border: 1px solid @white;
  border-radius: 4px;
}
.project-popup {
  left: 0 !important;
  margin-bottom: -85px;
  > div:first-child {
    width: 355px;
    border-radius: 2px;
    > div {
      width: 100%;
      margin: 5px 10px;
    }
  }
  > div:nth-child(2) {
    display: none;
  }
  > a:last-child {
    display: none;
  }
  .pop-up-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    > span {
      font-weight: 500;
      font-size: 14px;
      >span:first-child{
        color: @greyShade;
      }
      > span:last-child {
        color: @black;
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;