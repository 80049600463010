@import 'styles/variable.less';

.filerepo-table {
  height: calc(100% - 240px);
  overflow-y: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: @veryLightGray;
    border-radius: 3px;
  }
}
.fileReposataryTable {
  table {
    table-layout: unset !important;
    > thead {
      font-size: 12px;
      > tr {
        > th {
          background-color: @lightWhite;
          text-align: start;
          text-transform: uppercase;
          color: @lightGray;
          &::before {
            display: none;
          }
        }
        >th:last-child{
          text-align: center !important;
        }
      }
    }
    > tbody {
      > tr {
        &:hover > td {
          background: unset !important;
        }
        > td {
          font-size: 14px;
          border-bottom: 1px solid @cream1;
          .edit-file-btn {
            background-color: unset;
            border: 0;
            text-decoration: none;
            cursor: pointer;
            height: 20px;
            padding: 0;
          }
          .cheakedBtn {
            background-image: unset !important;
            background-color: @white !important;
            border: 1px solid @black;
            min-width: 34px;
            height: 18px;
            box-shadow: unset;
            > div {
              &::before {
                background: @red;
                box-shadow: none;
              }
            }
          }
          .disable-check-btn{
            border: none;
          }
          .ant-switch-checked {
            background-image: unset !important;
            background-color: @white !important;
            border: 1px solid @green;
            min-width: 34px;
            height: 18px;
            box-shadow: unset;
            > div {
              &::before {
                background: @green;
                box-shadow: none;
              }
            }
          }
          .switchAction {
            > span {
              color: @black;
            }
          }
          > div {
            > div {
              height: 18px;
            }
          }
          .uploadedByColumn {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            .editedText {
              color: @primary;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.edit-modal {
  border-radius: 4px;
  > div {
    padding: 0;
    .ant-modal-close {
      top: 25px !important;
      right: 10px !important;
    }
    > div {
      border-top: 0;
      border-bottom: 0;

      &:nth-child(2) {
        padding-bottom: 0;
        > div {
          border-bottom: 0.5px solid @greyShade2;
          padding: 20px 0px 12px 0px;
          margin-bottom: 20px;
          > span {
            font-weight: 500;
            font-size: 20px;
          }
        }
      }
      &:nth-child(3) {
        padding: 0 25px;
        .edit-form {
          > div:first-child,
          > div:nth-child(3) {
            width: 48%;
          }
        }
      }
      &:last-child {
        direction: rtl;
        height: 56px;
        background: @lightdarkGray;
        padding: 12px 16px;
        button:first-child {
          margin-right: 8px;
        }
        button:last-child {
          margin-right: 15px;
        }
      }
    }
  }
  .formField {
    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: @btnTitle;
    }
    .buffer-field {
      width: 100%;
    }
    .formInputField {
      padding: 8px;
      background: @secondary;
      color: @lightBlack;
      font-weight: 500;
      font-size: 14px;
    }
    .formSelectField {
      > div {
        background: @secondary;
        font-size: 14px;
      }
    }
    .edit-form-select {
      .ant-select-selection-item {
        font-weight: 500;
        color: @lightBlack;
      }
    }
  }
}
.admin-upload {
  background-color: @analyticBack !important;
  &:hover {
    background-color: @analyticBack !important;
  }
  .uploadedByColumn {
    > span:first-child {
      color: @primary;
      font-weight: 500;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;