@import 'styles/variable.less';

.userMangementLayout {
  background-color: @white;
  .mainTitleContent {
    .addMemberBtn {
      background-color: @white;
      color: @primary;
      &:hover {
        background-color: @primary;
        color: @white;
      }
    }
    > div {
      .backIcon {
        height: 20px !important;
        width: 20px !important;
        fill: @black !important;
        margin-top: 0px;
      }
      > div {
        > span {
          > span {
            color: @titleColor;
            text-shadow: none;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }

  .user-mangement-content {
    margin: 0 20px 20px;
    height: calc(100vh - 157px);
    border-radius: 5px;
    position: relative;
    .user-disable-btn {
      position: absolute;
      right: 10px;
      z-index: 999;
      top: 4px;
      color: @roottableth;
      > button {
        min-width: 38px;
        height: 20px;
        line-height: 20px;
        left: 8px;
        border: 1px solid @roottableth;
        background-color: @white;
        > div {
          top: 3px;
          left: 4px;
          width: 12px;
          height: 12px;
          &::before {
            background-color: @avatarTitle;
            box-shadow: none;
          }
        }
        &:active,
        &:hover,
        &::before {
          box-shadow: none;
          outline: none;
        }
      }
      .ant-switch-checked {
        border: 1px solid @green;
        background-color: @white;
        > div {
          top: 3px;
          left: calc(100% - 16px);
          width: 12px;
          height: 12px;
          &::before {
            background-color: @green;
            box-shadow: none;
          }
        }
        &:active,
        &:hover,
        &::before {
          box-shadow: none;
          outline: none;
        }
      }
    }
    > div {
      height: 100%;
      > div {
        &:nth-child(1) {
          margin-bottom: 0;
          &::before {
            display: none;
          }
        }
        &:nth-child(2) {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
            }
          }
        }
        > div {
          .ant-tabs-nav-list {
            background: @analyticBack;
            .ant-tabs-tab-active {
              padding: 0;
              transition: none;
              margin: 0;
              > div {
                border: 1px solid @primary !important;
                padding: 5.6px 17.3px !important;
                text-shadow: none;
                color: @primary;
                border-radius: 5px 5px 0px 0px;
              }
            }
            > div {
              padding: 0;
              margin: 0;
              > div {
                background: @analyticBack;
                color: @analyticTitle;
                font-weight: 600;
                font-size: 12px;
                text-shadow: none;
                letter-spacing: 0.5px;
                padding: 6.3px 18.3px !important;
              }
            }
          }
        }
      }
    }
  }
  .name-avatar {
    background: @white;
    border: 0.5px solid @avatarTitle;
    color: @avatarTitle;
    margin-right: 8px;
  }
  .userMangementTabel {
    border: 1px solid @primary;
    height: 100%;
    overflow-y: auto;
    border-radius: 0px 5px 5px 5px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: @veryLightGray;
      border-radius: 3px;
    }
    > div {
      > div {
        > div {
          > div {
            > div {
              overflow: unset !important;
              > table {
                > thead {
                  font-size: 18px;
                  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
                  > tr {
                    > th {
                      background-color: @white;
                      text-align: start;
                      &:first-child {
                        padding-left: 50px;
                      }
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
                > tbody {
                  > tr {
                    &:nth-child(odd) {
                      background-color: @lightWhite;
                    }
                    > td {
                      border: none;
                      font-weight: 500;
                      font-size: 14px;
                      color: @avatarTitle;

                      &:first-child {
                        padding-left: 50px;
                      }

                      &:nth-child(odd) {
                        background: none;
                      }
                      &:nth-child(even) {
                        background: none;
                      }
                      .avatar {
                        div {
                          span {
                            margin-top: 5px;
                          }
                        }
                      }
                      .action-content {
                        gap: unset !important;
                        .remove-title {
                          margin-right: 5px;
                        }
                      }
                    }
                    .in-progress {
                      color: @orange;
                    }
                    .not-started {
                      color: @red;
                    }
                    .closed {
                      color: @primary;
                    }
                    .completed {
                      color: @green;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.remove-member-modal {
  border-radius: 4px;
  > div {
    padding: 0;
    .ant-modal-close {
      top: 25px !important;
      right: 10px !important;
    }
    > div {
      border-top: 0;
      border-bottom: 0;

      &:nth-child(2) {
        padding-bottom: 0;
        > div {
          border-bottom: 0.5px solid @greyShade2;
          padding: 20px 0 12px 5px;
          > span {
            font-weight: 500;
            font-size: 20px;
          }
        }
      }
      &:nth-child(3) {
        padding: 0 25px;

        height: 332px;
        overflow-y: auto;
      }
      &:last-child {
        direction: rtl;
        height: 56px;
        background: @lightWhite;
        padding: 12px 16px;
        button:first-child {
          margin-right: 8px;
        }
        button:last-child {
          margin-right: 15px;
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;