@import 'styles/variable.less';

.modalFormContent {
  > p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: @primary;
  }

  .addmember-form {
    background: @white;
    border: 1px solid @primary;
    border-radius: 4px;
    padding: 40px 44px 20px 44px;

    .formField {
      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: @titleColor;
        > span {
          > span {
            color: @red;
            font-size: 18px;
            padding-left: 2px;
          }
        }
      }

      .formInputField {
        padding: 8px;
        background: @secondary;
        color: @primary;
        font-weight: 500;
        font-size: 14px;
      }

      .formSelectField {
        > div {
          padding: 6px 0;
          background: @secondary;
          font-size: 14px;

          > div {
            visibility: hidden !important;
            height: 26px;

            &:before {
              visibility: visible !important;
              content: 'Search members' !important;
              color: @cream3;
              padding: 3px 0 0 8px;
            }
          }
        }
      }
    }
    .selected-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
      .member-list {
        border: 1px solid @primary;
        padding: 3px 8px 3px 15px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin-right: 2px;
        margin-bottom: 2px;
        > span {
          margin-right: 5px;
        }
      }
    }
    .form-select-content {
      margin-bottom: 5px;
    }

    .formButton {
      padding-top: 40px;
      text-align: end;

      .formCancelbtn,
      .formCreatebtn {
        padding: 0 25px;
      }

      .formCreatebtn {
        margin-left: 20px !important;
      }
    }
  }
}
.member-dropdown {
  background-color: @white !important;
  z-index: 9999;
  > div {
    > label {
      width: 100%;
      > span:last-child {
        color: @cream3 !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  .ant-select-item-option-state {
    display: none !important;
  }
}
.ant-select-item-option-selected {
  > div {
    > label {
      > span:last-child {
        color: @primary !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;