@import 'styles/variable.less';

.add-timeline-modal {
  > div:nth-child(2) {
    padding: 15px;
    > button {
      right: 15px;
    }
    > div:nth-child(2) {
      padding: 0px 20px 10px 20px;
      border-bottom: 0.7px solid @lightGreyShade;
    }
    > div:last-child {
      padding: 0px 8px 0px 20px;
      margin-top: 10px;
    }
  }
}
.main-project-stage {
  height: 470px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: @veryLightGray;
    border-radius: 3px;
  }
}
.project-stage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid @lightWhite2;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-right: 8px;
  > span {
    font-weight: 500;
    font-size: 16px;
    color: @lightBlack;
  }
}
.project-date {
  width: 233px;
}
.add-timeline-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: @aoiback;
  padding: 10px 10px;
  margin-top: 5px;
  > button {
    padding: 4px 25px;
    border-radius: 2px;
    > span {
      font-weight: 500;
      font-size: 16px;
      text-shadow: none;
    }
  }
  .cancel-btn {
    margin-left: 25px;
  }
}
.project-stage-item {
  margin-bottom: 0;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;