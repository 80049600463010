@import 'styles/variable.less';

.main-org-content {
  height: 100%;
  .main-title-content {
    padding: 7px 25px;
    .page-title {
      text-shadow: none;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: @titleColor !important;
    }
  }
  .main-add-org-content {
    height: calc(100vh - 140px);
    border: 1px solid @primary;
    margin: 0 20px;
    border-radius: 4px;
    background: @white;
    justify-content: center;
    align-content: space-evenly;
    padding: 5%;
    .add-org-form-col {
      > div {
        justify-content: center;
      }
      .main-orgcontent-form {
        .org-form-item {
          margin-bottom: 30px;
          > div {
            > div:first-child {
              padding: 0px 0 18px;
              > label {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: @titleColor;
              }
            }
            > div:last-child {
              .org-input {
                padding: 16.5px 11px;
                background-color: @secondary;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: @primary;
              }
            }
          }
        }
        .inlineContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .inputFirstName {
            width: 48%;
            .last-name-label {
              opacity: 0;
            }
          }
        }
      }
    }
    .divider-col {
      > div {
        height: 81%;
        top: 43px;
        border-left: 1px solid @greyShade;
        margin: 0 35px;
        @media (max-width: 1600px) {
          margin: 0 26px;
        }
        @media (max-width: 1440px) {
          margin: 0 25px;
        }
      }
    }
    .upload-file-col {
      top: 4px;
      .upload-title {
        display: block;
        height: 10%;
        font-weight: 500;
        font-size: 20px;
        line-height: 14px;
        color: @titleColor;
      }
      .upload-main {
        position: relative;
        cursor: pointer;
        background-color: @lightdarkGray;
        border-radius: 4px;
        width: 100%;
        height: 81%;
        .logo-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .image-uplode-btn {
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
        }
        .selected-image-btn {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
        .add-image-icon {
          position: absolute;
          top: 48%;
          left: 47%;
        }
      }
    }
  }
}
.btn-col {
  > div {
    justify-content: center;
    > div:first-child {
      .cancel-btn {
        padding: 6.4px 27px;
        > span {
          color: @btnLabel;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
    > div:last-child {
      text-align: end;
      .add-btn {
        padding: 6.4px 41px;
        > span {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
}
.crop-image-modal {
  > div {
    > div {
      &:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        input[type='range'] {
          -webkit-appearance: none;
          width: 40%;
          margin-left: 8px;
        }
        input[type='range']:focus {
          outline: none;
        }
        input[type='range']::-webkit-slider-runnable-track {
          width: 100%;
          height: 5px;
          cursor: pointer;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          background: @veryLightGray;
          border-radius: 10px;
          border: 0px solid #000101;
        }
        input[type='range']::-webkit-slider-thumb {
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: @primary;
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -8px;
        }
        input[type='range']:focus::-webkit-slider-runnable-track {
          background: @veryLightGray;
        }
        input[type='range']::-moz-range-track {
          width: 100%;
          cursor: pointer;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          background: @primary;
          border-radius: 25px;
          border: 0px solid #000101;
        }
        input[type='range']::-moz-range-thumb {
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          border: 0px solid @black;
          height: 20px;
          width: 20px;
          border-radius: 7px;
          background: @primary;
          cursor: pointer;
        }
        input[type='range']::-ms-track {
          height: 5px;
          cursor: pointer;
          background: transparent;
          border-color: transparent;
          border-width: 39px 0;
          color: transparent;
        }
        input[type='range']::-ms-fill-lower {
          background: @primary;
          border: 0px solid #000101;
          border-radius: 50px;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
        }
        input[type='range']::-ms-fill-upper {
          background: @primary;
          border: 0px solid #000101;
          border-radius: 50px;
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
        }
        input[type='range']::-ms-thumb {
          box-shadow: 0px 0px 0px @black, 0px 0px 0px #0d0d0d;
          border: 0px solid @black;
          height: 20px;
          width: 39px;
          border-radius: 7px;
          background: @primary;
          cursor: pointer;
        }
        input[type='range']:focus::-ms-fill-lower {
          background: @primary;
        }
        input[type='range']:focus::-ms-fill-upper {
          background: @primary;
        }
        .crop-modal-btn {
          margin-right: 8px;
        }
      }
    }
  }
}
.cropper-content {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  height: 435px;
  background-color: @veryLightGray;
  .cropper-modal {
    background-color: @white;
  }
}
.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;