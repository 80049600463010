@import 'styles/variable.less';

.main-aoi-content {
  display: flex;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 124px);
}
.approve-modal {
  z-index: 99;
  height: 177px;
  .approve-modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    .modal-title {
      font-weight: 500;
      font-size: 24px;
      color: @titleColor;
      margin-bottom: 40px;
    }
    .notify-content {
      font-weight: 500;
      font-size: 20px;
      color: @titleColor;
      margin-bottom: 20px;
    }
    .modal-content-btn {
      > button {
        height: 36px;
        padding: 3px 50px;
        box-shadow: none;
      }
      > button:first-child {
        margin-right: 20px;
      }
      > button:last-child {
        background-color: @ghostbtnBack;
        border-color: @ghostbtnBack;
        &:hover,
        &:focus,
        &:focus:hover,
        &::after {
          border: 0;
          outline: 0;
          border-color: @ghostbtnBack;
          box-shadow: none;
          background-color: @ghostbtnBack;
        }
      }
    }
  }
}

.points-popup {
  background: @blackShade1;
  border-radius: 2px;
  margin-bottom: 0px;
  bottom: -9px !important;
  left: 16px !important;
  height: 23px;
  width: 70px;
  > div:first-child {
    background: unset;
    box-shadow: none;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      margin: 0;
      gap: 10px;
      display: flex;
      align-items: center;
      > svg:first-child {
        margin: 3px 7px 3px 3px;
      }
      > svg:last-child {
        margin: 3px 7px 3px 27px;
      }
    }
  }
  > div:nth-child(2) {
    display: none;
  }
  > a {
    color: @white !important;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;