@import 'styles/variable.less';

.custom-layer {
  background-color: #413e39;
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 13px;
  box-shadow: none;
  border-radius: 2px;
  cursor: pointer;
}
.vector-layer {
  position: absolute;
  z-index: 10001;
  top: 40px;
  right: 12px;
  width: 200px;
  float: right;
  background-color: @white;
  cursor: default;
  .uploaded-layer {
    border-bottom: 0;
    padding-bottom: 10px;
    > div:first-child {
      padding: 5px 10px 0px 12px;
      background-color: @white;
      > span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: @titleColor;
      }
    }
    > div:last-child {
      border-top: 0;
      > div {
        padding: 0;
        margin: 5px 10px;
        border-top: 1px solid @cream;

        .vector-layer-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 7px 0px;
          > span {
            display: flex;
            cursor: pointer;
            z-index: 999999;
          }
          > button {
            margin-right: 3px;
          }
          > div {
            display: flex;
            align-items: center;
            .box-color {
              width: 12px;
              height: 12px;
              border-radius: 2px;
              margin-right: 8px;
              margin-top: 2px;
            }
            .box-color-bee {
              width: 12px;
              height: 3px;
              background-color: @black;
              margin-right: 8px;
            }
            .layer-content-title {
              font-weight: 500;
              font-size: 12px;
              color: @lightBlack;
              width: 124px;
              margin-right: 3px;
              white-space: break-spaces;
              font-family: 'ManropeRegular';
            }
            .active-layer {
              color: @primary;
            }
          }
        }
      }
    }
  }
  .upload-layer-content {
    > div:last-child {
      > div {
        max-height: 160px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: @veryLightGray;
          border-radius: 3px;
        }
      }
    }
  }
  .satsure-layer {
    > div:last-child {
      > div {
        > div:last-child {
          max-height: 182px !important;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: @veryLightGray;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
.layer-dd-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 29px;
  width: 63px;
}
.zoom-plus,
.zoom-minus,
.polygon-icon,
.move-icon,
.map-icon {
  background-color: rgba(37, 37, 37, 0.75);
  border: 0;
  box-shadow: none;
  height: 28px;
  padding: 5px 0px;
  width: 28px;

  &:hover {
    box-shadow: none;
    border: 0;
    background-color: rgba(37, 37, 37, 0.75);
  }
  &:focus {
    box-shadow: none;
    border: 0;
    background-color: rgba(37, 37, 37, 0.75);
  }
}
.zoom-minus {
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-top: 2px;
  }
}
.zoom-minus,
.move-icon,
.polygon-icon,
.map-icon {
  margin-top: 5.6px;
}
.move-icon {
  padding: 2.7px 0;
}
.empty-layers {
  display: flex;
  justify-content: center;
  color: @red;
  margin-top: 5px;
}
.points-tooltip {
  background: @titleColor;
  bottom: 8px !important;
  border-radius: 2px;
  color: @white;
  border: none;
  box-shadow: none;
  width: 75px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;

  > div:first-child {
    background: @titleColor;
    color: @white;
    box-shadow: none;
    > div {
      width: 100% !important;
      margin: 0;
      margin: 5px 7px;
      padding-left: 5.5px;
    }
  }
  > div:nth-child(2),
  > a:last-child {
    display: none;
  }
  &::before {
    display: none;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;