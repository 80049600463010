@import 'styles/variable.less';

.avatar {
  > div {
    width: 35px;
    height: 35px;
    box-shadow: 2px 0px 4px rgba(103, 103, 103, 0.15);
    border-radius: 50%;
    position: relative;
    margin-left: -5px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @black;
    background-color: @white;
    text-align: center;
    border: 0.5px solid @avatarTitle;
    &:hover {
      background-color: @verylightBlack;
      z-index: 2;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 !important;
    }
    @media (max-width: 1441px) {
      width: 24px;
      height: 24px;
      span {
        font-size: 10px;
      }
    }
    @media (max-width: 1321px) {
      width: 23px;
      height: 23px;
      span {
        font-size: 10px;
      }
    }
  }
  .first-div {
    > span {
      color: @white;
      font-weight: 500;
    }
    background-color: @primary !important;
  }
}

.avatars {
  display: flex;
  justify-content: left;
  align-items: center;
  direction: rtl;
  text-align: left;
  cursor: pointer;
}
.avtar-tooltip {
  > div {
    > div:last-child {
      color: @black !important;
      background-color: @secondary !important;
      .avtars-name {
        display: flex !important;
        flex-direction: column !important;
        > span:last-child {
          text-transform: capitalize;
        }
      }
    }
  }
}

.p-r-8 {
  padding-left: 5px;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;