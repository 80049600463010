@import 'styles/variable.less';

.left-content {
  padding: 8px 15px 0 15px;
  width: 250px;
  background-color: @white;
  .main-tab {
    height: 100%;
    width: 100% !important;
    > div:first-child {
      width: 100% !important;
      > div {
        width: 100%;
        background: @white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
        > div {
          width: 99%;
          div:nth-child(1),
          div:nth-child(2) {
            padding: 5px 0;
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0;
            > div {
              .tab-title-inr {
                color: @analyticTitle;
              }
            }
          }
          .ant-tabs-tab-active {
            > div {
              .tab-title-inr {
                color: @primary !important;
              }
            }
          }
        }
      }
    }
    > div:last-child {
      > div {
        height: 100%;
      }
    }
  }
  .end-point-content {
    @media (max-width: 1440px) {
      height: auto;
    }
  }
  .point-tab {
    height: 100%;
    .main-points-tab-content {
      height: calc(100% - 25%);
      @media (max-width: 1440px) {
        height: calc(100% - 26%);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: @veryLightGray;
          border-radius: 3px;
        }
      }
      @media (max-width: 1200px) {
        height: calc(100% - 15%);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: @veryLightGray;
          border-radius: 3px;
        }
      }
    }
    .full-height {
      height: 100%;
    }
    .zero-height {
      display: none;
    }
  }
  .start-point-content,
  .end-point-content,
  .start-end-point-content {
    margin-bottom: 10px;
    @media (max-width: 1440px) {
      height: auto;
    }
    .aoi-tab-select {
      width: 100%;
    }
    
    .start-point-btn {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .or-content {
    font-weight: 500;
    font-size: 16px;
    color: @black;
    display: block;
    text-align: center;
    padding: 15px 0;
    border-top: 1px solid @cream;
  }
  .start-end-point-submit-btn {
    height: 22.5%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media (max-width: 1200px) {
      height: 13%;
    }
    @media (max-width: 1024px) {
      padding-bottom: 0px;
    }
    > button {
      width: 100%;
    }
  }
  .aoi-tab {
    height: 100%;
    .aoi-tab-content {
      height: 90%;
      margin-bottom: 10px;
      @media (max-width: 1024px) {
        height: 87%;
      }
      .aoi-title {
        padding-bottom: 5px;
        .aoi-title-inr {
          font-weight: 600;
          font-size: 14px;
          color: @titleColor;
          text-shadow: none;
        }
      }
      .radio-group-content {
        width: 100%;
      }
      .selected-aoi-file {
        margin-top: 7px;
      }
      > div:nth-child(2) {
        max-height: 84%;
        overflow-y: auto;
        width: 100% !important;
        &::-webkit-scrollbar {
          display: none !important;
        }
        @media (max-width: 1440px) {
          max-height: 81%;
        }
        @media (max-width: 1330px) {
          max-height: 78%;
        }
        @media (max-width: 1024px) {
          max-height: 70%;
        }
        .space-aoi-content {
          width: 100% !important;
          .aoi-card {
            background: @white;
            border-radius: 4px;
            > div {
              padding: 5px;
              padding-left: 6px;
              padding-right: 0;
              .inner-card-content {
                > div:first-child {
                  margin-right: 3px !important;
                  .list-aoi {
                    span:first-child {
                      top: 12px;
                      > span {
                        height: 13px;
                        width: 13px;
                        &::after {
                          top: 8px;
                          left: 8px;
                          width: 11px;
                          height: 11px;
                        }
                      }
                    }
                  }
                }
                .inner-card-content-col {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  > div {
                    span:first-child {
                      font-size: 12px;
                      padding: 0;
                      color: @primary;
                      text-transform: uppercase;
                    }
                    span:last-child {
                      font-size: 10px;
                      color: @lightGray;
                    }
                  }
                  .upload-name {
                    font-size: 10px;
                    color: @lightGray;
                    > span {
                      color: @lightGray;
                      text-transform: uppercase;
                    }
                  }
                }
                .inner-card-content-icon {
                  > div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding-top: 3px;
                    > a:first-child {
                      padding-right: 3px;
                    }
                    > span {
                      cursor: pointer;
                    }
                  }
                }
                .remove-download {
                  > div {
                    justify-content: flex-end;
                    > div {
                      > a {
                        padding-right: 0;
                        > img {
                          width: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .aoi-card-purple {
            border: 1px solid @purple;
            border-left: 5px solid @purple;
          }
          .route-card-none {
            border: 1px solid @notification;
            border-radius: 4px;
          }
        }
      }
      .aoi-select-submit-btn {
        padding-top: 20px;
        .aoi-tab-select {
          width: 100% !important;
          margin-bottom: 10px;
        }
        > button {
          width: 100%;
        }
        .aoi-upload-file {
          margin-bottom: 10px;
          > input {
            color: @primary;
          }
        }
      }
      .empty-aoi-list {
        padding-top: 0px;
      }
      .empty-route-list {
        padding-top: 0px;
      }
    }
  }
}
.aoicoord-filename {
  color: @primary;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: block;
  margin-top: 8px;
}
.aoicoord-upload {
  color: @primary;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  display: block;
}
.aoi-tab-submit-btn {
  display: flex;
  justify-content: space-between;
  > button {
    height: 36px;
    padding: 4px 26px;
  }
  .aoi-selection-mng {
    width: 100%;
  }
}
.approved-aoi {
  justify-content: flex-start;
  margin-top: 25px;
}

.decline-btn,
.approve-btn {
  width: auto !important;
  padding: 4px 23px;
  height: 34px;
}
.approve-btn {
  margin-left: 10px;
}
.start-inp {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 0;
  background: @analyticBack;
  &:focus {
    border: 0;
  }
  > input {
    color: @primary;
    background: @analyticBack;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}
.empty-point {
  font-size: 14px;
  color: @primary;
  background: @analyticBack;
  display: block;
  padding: 7px 0px 7px 10px;
  line-height: 17px;
}
.admin-approve {
  display: block;
  color: @primary;
}
.approve-dwnld {
  display: flex;
  align-items: center;
  margin-top: 5px;
  .approved {
    color: @primary;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
}
.approve-aoi-selection {
  margin-top: 0;
}
.aoi-approve-modal,
.satsure-approve-modal {
  .aoi-approve-content,
  .satsure-approve-content {
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: column;
    .notify-title,
    .notify-msg {
      font-weight: 500;
      color: @titleColor;
    }
    .notify-title {
      font-size: 24px;
      line-height: 29px;
    }
    .notify-msg {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
.approved-points {
  justify-content: flex-start !important;
  height: 21% !important;
  padding-bottom: 0 !important;
}
.select-title {
      display: block;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 14px;
      color: @titleColor;
      text-shadow: none;
      letter-spacing: 0.5px;
      > span {
        color: @red;
        padding-left: 2px;
      }
    }

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;