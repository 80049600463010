@import 'styles/variable.less';

.titleContent {
  color: @primary;
  font-weight: 500;
  margin-bottom: 10px;
}
.mainContentForm {
  border: 1px solid @primary;
  padding-top: 60px;
  padding-bottom: 40px;
  border-radius: 4px;
  .mainFormDiv {
    margin: 0 30px 0 30px;
  }
}
.inputMainContent {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .emailInputContent {
    width: 97%;
    margin-bottom: 0;

    .innerInputContent {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      color: @primary;
      font-weight: 500;
    }
    @media (max-width: 1024px) {
      width: 96%;
    }
  }
}
.formField {
  margin-bottom: 16px !important;
}
.formField.ant-form-item-has-error {
  > div:last-child {
    margin-bottom: 0px !important;
  }
}
.memberBtn {
  button {
    border: 0px;
    color: @greyShade3;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-left: 0;
  }
  button:active {
    border-color: unset !important;
    border: 0 !important;
    color: @greyShade3;
    box-shadow: none !important;
    background: none !important;
  }
  button:hover {
    color: @greyShade3 !important;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;