@import 'styles/variable.less';

.main-route-content {
  display: flex;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 124px);
  .route-tab {
    padding: 19px 15px 0 15px;
    width: 230px;
    background-color: @white;
    height: 99.8%;
    .route-tab-content {
      max-height: 85%;
      margin-bottom: 10px;
      overflow-y: auto;
      width: 100% !important;
      &::-webkit-scrollbar {
        display: none !important;
      }
      @media (max-width: 1330px) {
        max-height: 82%;
      }
      @media (max-width: 1200px) {
        max-height: 79%;
      }
      .route-title {
        // padding-bottom: 10px;
        .route-title-inr {
          font-weight: 600;
          font-size: 14px;
          color: @titleColor;
          text-shadow: none;
        }
      }
      .space-route-content {
        width: 100% !important;
        overflow-y: auto;
        max-height: 81%;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none !important;
        }
        .route-card {
          background: @white;
          border-radius: 4px;
          line-height: unset;
          margin-bottom: 3px;
          > div {
            padding: 3px 3px 8px 5px;

            .inner-card-content {
              display: flex;
              justify-content: space-around;
              width: 100%;
              .inner-card-content-col {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 76%;
                > div {
                  padding-top: 8px;
                  span:first-child {
                    font-size: 12px;
                    padding: 0;
                    padding-right: 3px;
                    color: @primary;
                  }
                  span:last-child {
                    font-size: 10px;
                    color: @lightGray;
                  }
                }
                .upload-name {
                  font-size: 10px;
                  color: @lightGray;
                  line-height: 12px;
                  > span {
                    color: @lightGray;
                    text-transform: uppercase;
                  }
                }
              }
              .inner-card-content-icon {
                > div {
                  display: flex;
                  justify-content: space-around;
                  width: 100%;
                  padding-top: 10px;
                  > a:first-child {
                    padding-right: 3px;
                  }
                  > span {
                    cursor: pointer;
                  }
                }
              }
              .remove-download {
                > div {
                  justify-content: flex-end;
                  padding-right: 6px;
                }
              }
            }
          }
        }
      }

      .empty-route-list {
        padding-top: 0px;
      }
    }
  }
}
.upload-file-btn {
  color: @primary;
  outline: 0;
  border: 0;
  box-shadow: none;
  background-color: @white;
  padding: 4px 0;
}
.route-select-submit-btn {
  .route-tab-select {
    width: 100% !important;
    margin-bottom: 10px;
  }
  > button {
    width: 100%;
  }
  .route-upload-file {
    margin-bottom: 10px;
    > input {
      color: @primary;
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;