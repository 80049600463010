@font-face {
  font-family: 'ManropeBold';
  src: local('ManropeBold'), url('./fonts/ttf/ManropeBold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeExtraBold';
  src: local('ManropeExtraBold'), url('./fonts/ttf/ManropeExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeExtraLight';
  src: local('ManropeExtraLight'), url('./fonts/ttf/ManropeExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeLight';
  src: local('ManropeLight'), url('./fonts/ttf/ManropeLight.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeMedium';
  src: local('ManropeMedium'), url('./fonts/ttf/ManropeMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeRegular';
  src: local('ManropeRegular'), url('./fonts/ttf/ManropeRegular.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeSemiBold';
  src: local('ManropeSemiBold'), url('./fonts/ttf/ManropeSemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'ManropeVariableFontWeight';
  src: local('ManropeVariableFontWeight'),
    url('./fonts/ttf/ManropeVariableFontWeight.ttf') format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'ManropeRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5 !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: 'ManropeRegular' !important;
}
