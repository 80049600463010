@import 'styles/variable.less';

.coord-modal-content {
  > div:nth-child(2) {
    > button {
      top: 5px;
      right: 7px;
    }
    > div:nth-child(2) {
      border: 0;
      padding-top: 20px;
      > div {
        .modal-title {
          color: @titleColor;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
        }
        &:after {
          content: ' ';
          display: block;
          background: @greyShade2;
          height: 0.5px;
          width: 87%;
          position: absolute;
          top: 17%;
        }
      }
    }
    > div:last-child {
      padding: 15px 25px 40px;
      .coord-form {
        .latitude-item {
          margin-bottom: 15px;
        }
        .longitude-item {
          margin-bottom: 40px;
        }
        .lat-lng-inp {
          border: 1px solid @lightWhite2;
          border-radius: 2px;
          background: @secondary;
          padding: 5px 11px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: @lightBlack;
        }
        .latitude-label,
        .longitude-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: @btnTitle;
        }
        .coord-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          > button {
            height: 36px;
            font-size: 18px;
            line-height: 18px;
            text-shadow: none;
            box-shadow: none;
            border-radius: 2px;
          }
          > button:first-child {
            font-weight: 600;
            margin-right: 25px;
            padding: 8px 30px;
          }
          > button:last-child {
            font-weight: 500;
            padding: 8px 23px;
            color: @btnLabel;
            border: 0;
            background: @lightWhite2;

            > button:hover {
              border: inherit;
              color: @btnLabel;
              background: @lightWhite2;
              border: 0;
            }
          }
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;