@import 'styles/variable.less';

.mainContentDiv {
  margin-top: 3%;
  .titleContent {
    color: @primary;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .mainContentForm {
    border: 1px solid @primary;
    padding-top: 60px;
    padding-bottom: 0;
    border-radius: 4px;
    input {
      padding: 5px 10px;
      background-color: @secondary;
    }
    .mainFormDiv {
      margin: 0 30px 0 30px;
      h3 {
        font-size: 20px;
        font-weight: 500;
        color: @titleColor;
        span {
          color: @red;
        }
      }
      .inlineContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .inputFirstName {
          width: 48%;
        }
      }
      .inputPhone {
        width: 48% !important;
      }
      .addMemberBtn {
        button {
          font-weight: 500;
          border-radius: 4px;
        }
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;