@import 'styles/variable.less';

.remove-member-org-modal {
  > div {
    &:nth-child(2) {
      > div {
        &:nth-child(3) {
          height: 280px;
        }
      }
    }
  }
}
.remove-member-detail {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.remove-member-avatar {
  font-weight: 500;
  font-size: 28px;
  color: @avatarTitle;
  background: @white;
  border: 1px solid @avatarTitle;
}
.remove-member-text {
  width: 75%;
  display: block;
  text-align: center;
  margin: 0 auto;
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;