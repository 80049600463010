@import 'styles/variable.less';

.route-content-row {
  width: calc(100% - 230px);
  position: relative;
  .map-container {
    width: 100%;
    height: 100%;
  }
  .bee-line-distance {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 9999;
    width: 231px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: @darkWhite;
    border-radius: 4px;
    padding: 10px;
    .finalized-aoi,
    .bee-line-aoi {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 12px;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
    .finalized-aoi {
      > div {
        height: 12px;
      }
    }
    .bee-line-aoi {
      padding-top: 3px;
      > div {
        height: 3px;
      }
    }
  }
  .dem-main-content {
    z-index: 1001;
    position: absolute;
    top: 100px;
    left: 15px;
    z-index: 9999;
    width: 231px;
    background: @darkWhite;
    border-radius: 4px;
    padding: 10px 12px 5px 12px;
    font-family: 'ManropeRegular' !important;
    > div:last-child {
      border: none;
    }
    .dem-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span:first-child {
        color: @black;
        font-size: 14px !important;
        font-weight: 600;
      }
      >svg{
        cursor: pointer;
      }
      .cheakedBtn {
        background-image: unset !important;
        background-color: @white !important;
        border: 1px solid @black;
        min-width: 33px;
        height: 16px;
        box-shadow: unset;
        > div {
          width: 10px;
          height: 10px;
          &::before {
            background: @lightGray;
            box-shadow: none;
          }
        }
      }
      .ant-switch-checked {
        background-image: unset !important;
        background-color: @white !important;
        border: 1px solid @green;
        min-width: 33px;
        height: 16px;
        box-shadow: unset;
        > div {
          width: 10px;
          height: 10px;
          &::before {
            background: @green;
            box-shadow: none;
          }
        }
      }
    }
    .dem-divider {
      margin: 0;
      margin-top: 5px;
      margin-bottom: 0px;
      color: @lightBlack;
      border-top: 1px solid @lightBlack;
    }
    .inr-dem-content {
      margin-top: 7px;
      border-bottom: 1px dashed @lightBlack;
      > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
          font-size: 15px !important;
          color: @black;
          font-weight: 500;
        }
        .show-dem-btn {
          margin: 0;
          padding: 0;
          border: 0;
          box-shadow: none;
          background: none;
          color: unset;
          border-radius: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
.route-analytics-data {
  display: flex;
  justify-content: center;
  z-index: 1001;
  width: 100%;
  position: absolute;
  bottom: 0;
  .route-analytics-main {
    z-index: 1001;
    background-color: rgba(255, 255, 255, 0.9);
    width: 98%;
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    cursor: default;
    .parameters-txt {
      z-index: 1001;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding-right: 3px;
      margin: 0;
      padding-bottom: 12px;
      > svg {
        cursor: pointer;
      }
      > span {
        font-family: 'ManropeRegular' !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #151515;
      }
    }
    .route-data {
      z-index: 1001;

      > div:first-child {
        .route-length {
          > span {
            display: block;
            font-weight: 500;
            font-size: 14px;
            color: #1b1b1b;
          }
        }
        > div:last-child {
          border-left: 1px solid @black;
          .route-params {
            padding-left: 22px;
            transition: all 0.5s;
            overflow-y: auto;
            padding-right: 5px;
            &::-webkit-scrollbar {
              width: 6px;
              background-color: @veryLightGray;
              border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background: @titleColor;
              border-radius: 5px;
            }
            .route-item {
              margin-bottom: 25px;
              border-left: 0.5px solid @greyShade;
              padding-left: 10px;
              > span {
                display: block;
                font-weight: 500;
                color: @roottableth;
                font-family: 'ManropeRegular' !important;
                font-style: normal;
              }
              > span:first-child {
                font-size: 16px;
              }
              > span:last-child {
                font-size: 13px;
              }
            }
            .route-item:first-child,
            .route-item:nth-child(7),
            .route-item:nth-child(13),
            .route-item:nth-child(19) {
              border: 0;
            }
            .route-item:first-child,
            .route-item:nth-child(7),
            .route-item:nth-child(13),
            .route-item:nth-child(19) {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }
  .expand-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    background: @white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -10px;
    cursor: pointer;
    z-index: 9999;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;