@import 'styles/variable.less';

.aoi-content-row {
  width: calc(100% - 230px);
  position: relative;
  .bee-line-distance {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 9999;
    width: 231px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    padding: 10px;
    .finalized-aoi,
    .bee-line-aoi {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 12px;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
    .finalized-aoi {
      > div {
        height: 12px;
      }
    }
    .bee-line-aoi {
      padding-top: 3px;
      > div {
        height: 3px;
      }
    }
  }
}
.leaflet-control-container {
  > div:nth-child(2) {
    right: 15px;
    > div:first-child {
      margin-right: 0;
    }
    > div {
      > div.leaflet-control {
        display: flex;
        flex-direction: column;
        margin-right: 0;
        position: absolute;
        top: 35vh;
        right: 0;
      }
    }
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;