@import 'styles/variable.less';

.projectHeaderRow {
  height: 56px;
  background-color: @darkGray;
  align-items: flex-start;
  padding: 11px 0;
  > div:first-child {
    display: flex;
    justify-content: center;
    .project-id {
      display: block;
      line-height: 17px;
      width: fit-content;
    }
  }
  > div:nth-child(3) {
    padding-right: 8px;
  }
  .project-point {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .project-title {
    display: block;
    width: fit-content;
    font-size: 12px;
    color: @subheader;
    font-weight: 600;
    line-height: 14px;
  }
  .project-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: @subheader;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .start-point-name {
    font-weight: 500;
    font-size: 14px;
    color: @subheader;
    line-height: 17px;
    padding-right: 5px;
  }
  .start-point-coord {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .last-updated-date {
    font-weight: 500;
    font-size: 14px;
    color: @subheader !important;
    line-height: 17px;
  }
  .not-defined {
    font-weight: 500;
    font-size: 14px;
    color: @subheader;
    line-height: 17px;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;