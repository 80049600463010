@import 'styles/variable.less';

.mainSideBar {
  background-color: @white;
  height: calc(100vh - 64px);
  .back-button {
    padding: 14px 25px;
    > div {
      > div {
        cursor: pointer;
        > div {
          margin-right: 11.75px;
        }
        > span {
          margin: 0;
          .back-title-main {
            display: flex;
            align-items: center;
            > span {
              margin-left: 11.75px;
              color: @lightGray;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  ul {
    list-style: none;
    margin-top: 5px;
    padding: 0;
    display: flex;
    justify-content: center;
    a {
      width: 230px;
      height: 44px;
      background: @white;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      padding: 0 12px;
      color: @analyticTitle;
      .link-title {
        padding-left: 12px;
        font-weight: 600;
        font-size: 16px;
        color: @analyticTitle;
      }
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          align-items: center;
          .link-title {
            padding-left: 12px;
            font-weight: 600;
            font-size: 16px;
            color: @analyticTitle;
          }
        }
        .notification {
          font-weight: 600;
          font-size: 14px;
          color: @red;
        }
      }
    }
    .activeLink {
      width: 230px;
      height: 44px;
      background: @primary;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 12px;
      color: @white;
      margin-bottom: 10px;
      .link-title {
        color: @white;
        font-weight: 600;
        padding-left: 12px;
      }
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
          .link-title {
            color: @white;
            font-weight: 600;
            padding-left: 12px;
          }
          .dot {
            height: 6px;
            width: 6px;
            background-color: @white;
            border-radius: 50%;
            margin-left: 5px;
          }
        }
        .notification {
          font-weight: 600;
          font-size: 14px;
          color: @white;
        }
      }
    }
  }
  > div:nth-child(2) {
    position: absolute;
    width: 48px !important;
    border-radius: 100%;
    background-color: @white;
    color: @primary;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    right: 18px;
    bottom: 10px;
  }

  .backIcon {
    fill: @lightGray !important;
    margin-top: 5px;
  }
}
.main-divider {
  display: flex;
  justify-content: center;
  .inner-divider {
    background-color: @veryLightGray;
    height: 1px;
    width: 85%;
  }
}
.ant-layout-sider-collapsed {
  .back-button {
    margin: 4.5px 0;
    > div {
      justify-content: center;
    }
  }
  > div {
    ul {
      padding: 0;
      display: flex;
      justify-content: center;
      > div {
        text-align: center;
        a {
          width: 58px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 14px;
        }
      }
    }
    .activeLink {
      width: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .inner-divider {
    width: 29%;
  }
}
.aoi-image {
  height: 20px;
  width: 20px;
}
.back-image {
  width: 13px;
  height: 9.5px;
}
.dot {
  height: 6px;
  width: 6px;
  background-color: @red;
  border-radius: 50%;
  margin-left: 5px;
}
.dot-point {
  position: absolute;
  height: 6px !important;
  width: 6px !important;
  background-color: @red;
  border-radius: 50%;
  padding: 0 !important;
}
.menu-tooltip-card {
  > div:first-child {
    margin-left: 8px;
  }
}

@primary-color: #6E3BD1;@page-header-heading-title: 24px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;